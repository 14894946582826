import React, { useState, useEffect } from 'react';
import logo from '../utils/logo.png';
import "../styles/plomberie.css";
import logoMiniWhite from "../utils/logo-miniature-blanc.png"



const Banner2Plomberie = () => {
    return (
        <div class="containerPlomberie">
            <div class="carreNoirPlomberie">
                <div class="phrase1Plomberie">Plomberie</div>
                <img src={logoMiniWhite} alt="logo" className="logoMiniBanner" />
            </div>
        </div>
    );
};

export default Banner2Plomberie;