import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import logo from '../utils/logo.png';
import "../styles/contact.css";


const Banner2Contact = () => {
    return (
        <div>
            <div className="">
            </div>
            <div class="containerContact">
                <div class="carreNoirContact">
                    <div class="phrase1">Contactez nous</div>
                </div>
            </div>
        </div>
    );
};

export default Banner2Contact;