import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom';

import "../styles/partenairesBannerContact.css";

import logoartipole from '../utils/logoartipole.png';
import logoqualitenr from '../utils/logoqualitenr.png';
import logovst from '../utils/logovst.png';
import logoffb from '../utils/logoffb.png';



export class PartenairesBannerContact extends PureComponent {
  render() {
    return (
      <div className="partenairesBannerContact">
        <Link  className="partenairesBannerLogos" to={"https://www.artipole.fr/nos-artisans-artipole/retz-energies"} target="_blank">
          <img src={logoartipole} alt="logo artipole" className="partenairesBannerLogo" />
        </Link>
        <Link  className="partenairesBannerLogos" to={"https://www.vst.coop/"} target="_blank">
          <img src={logovst} alt="logo vst" className="partenairesBannerLogo" />
        </Link>
        <Link  className="partenairesBannerLogos" to={"https://www.qualit-enr.org/entreprises/retz-energies/"} target="_blank">
          <img src={logoqualitenr} alt="logo qualite enr" className="partenairesBannerLogo" />
        </Link>
        <Link  className="partenairesBannerLogos" to={"https://www.ffbatiment.fr/"} target="_blank">
          <img src={logoffb} alt="logo ffb" className="partenairesBannerLogo" />
        </Link>
      </div>
    )
  }
}

export default PartenairesBannerContact;