import "../styles/energies.css"
import BannerElec from "../components/BannerElec";
import VotreComposant from "../components/VotreComposant";
import bastien1 from "../utils/elec1.jpg"; 
import logoOrange from "../utils/logo-miniature-orange.png"; 
import bastien02 from "../utils/elec2.jpg"
import PartenairesBannerContact from "../components/PartenairesBannerContact";
import QualifBanner from "../components/QualifBanner";

import Banner2Elec from "../components/Banner2Elec";
import Bouton2 from "../components/Bouton2";


const ElectriciteGenerale = () => {
    return (
        <div>
            <BannerElec />
            <Banner2Elec />
            <Bouton2 />
            <VotreComposant
                logo={logoOrange}
                h1="Electricité Générale"
                image1={bastien1}
                image2={bastien02}
                alt="ma description"
                titre=""
                paragraphs={["Retz Energies propose de nombreuses prestations en électricité.", "Que ce soit dans le neuf, la rénovation ou pour une mise aux normes de votre installation, nous intervenons dans les logements individuels et collectifs ainsi que dans les bâtiments tertiaires.", "Nous proposons des solutions pour:", "- Installation électrique neuve.", "- Rénovation électrique.", "- Courant fort et courant faible.", "- Tableaux électriques", "- Installation des prises de courant et interrupteurs.", "- Eclairage.", "- Mise aux normes.", "- Domotique.", "- Automatisme.", "- Réseaux informatiques.", "", "Contactez nous afin d'obtenir une étude et un devis gratuit pour votre projet."]}
            />
            <PartenairesBannerContact />
            <QualifBanner />

        </div>
    );
};

export default ElectriciteGenerale;