import Banner2Plomberie from "../components/Banner2Plomberie";
import BannerPlomberie from "../components/BannerPlomberie";
import PartenairesBannerContact from "../components/PartenairesBannerContact";
import QualifBanner from "../components/QualifBanner";

import VotreComposant from "../components/VotreComposant";
import bastien1 from "../utils/plomberie1.jpg";
import bastien02 from "../utils/plomberie2.jpg"
import logoBleu from "../utils/logo-miniature-bleu.png";
import Bouton2 from "../components/Bouton2";


const Plomberie = () => {
    return (
        <div>
            <BannerPlomberie />
            <Banner2Plomberie />
            <Bouton2 />
            <VotreComposant
                logo={logoBleu}
                h1="Plomberie"
                image1={bastien1}
                image2={bastien02}
                alt="ma description"
                titre=""
                paragraphs={['Vos besoins de travaux en plomberie sanitaire ou en chauffage pourront être réalisés afin de garantir des installations fiables et durables.', 'Nous garantissons de pouvoir intervenir sur toute installation de plomberie en chauffage ou en sanitaire afin de reprendre votre installation défaillante ou de créer un réseau dans le neuf.', 'Voici une liste non exhaustive des travaux proposés:', '- Rénovation de salle de bain (clé en main)', "- Installation / remplacement de ballon d'eau chaude", "- installation / remplacement de robinetterie", "- installation / remplacement de système de chauffage", "- installation / remplacement de WC", "- Filtration d'eau.", "- Dépannage sur tuyauterie ou systèmes", "- Reprise de réseau lors d'un changement de cuisine", "-Chauffe-eau solaire", "- Installation de solutions pour les personnes à Mobilité Réduite.", "Contactez-nous pour obtenir une étude et un devis gratuit."]}
            />
            <PartenairesBannerContact />
            <QualifBanner />
        </div>
    );
};

export default Plomberie;