import React from "react";
import { Routes, Route } from "react-router-dom";

import Home from "../pages/Home";
import Error from "../pages/Error";
import RenovationEnergetique from "../pages/RenovationEnergetique.jsx";
import ElectriciteGenerale from "../pages/ElectriciteGenerale.jsx";
import Plomberie from "../pages/Plomberie.jsx";
import Ventilation from "../pages/Ventilation.jsx";
import Contact from "../pages/Contact.jsx";
import MentionsLegales from "../pages/MentionsLegales.jsx";

const index = () => {
    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/RenovationEnergetique" element={<RenovationEnergetique />} />
            <Route path="/ElectriciteGenerale" element={<ElectriciteGenerale />} />
            <Route path="/Plomberie" element={<Plomberie />} />
            <Route path="/Ventilation" element={<Ventilation />} />
            <Route path="/Contact" element={<Contact />} />
            <Route path="/*" element={<Error />} />
            <Route path="/MentionsLegales" element={<MentionsLegales />} />

        </Routes>
    );
};

export default index;

