import "../styles/contact.css";
import BannerContact from "../components/BannerContact";
import Banner2Contact from "../components/Banner2Contact";
import logo from '../utils/logo.png';
import map from '../utils/mapREnergies.png';
import PartenairesBannerContact from "../components/PartenairesBannerContact";
import QualifBanner from "../components/QualifBanner";

const Contact = () => {
    return (
        <div>
            <BannerContact />
            <Banner2Contact />
            <div className="blocUnder">
                <img src={logo} alt="logo retzenergies" className="logoContact" />
            </div>
            <div className="boxesContact">
                <div className="boxContactMap">
                    <div className="box1Contact">
                        <img src={map} alt="zone d'intervention de Retz Energies" className="mapREnergie" />

                    </div>
                </div>
                <div className="boxContact">

                        <p className="">Contacts :</p>
                        <p className="">5 impasse des CHamps Fleuris, 44320 Chaumes en Retz</p>
                        <p className=""><i class="fa-solid fa-phone"></i>  06.65.43.53.06</p>
                        <p className=""><i class="fa-solid fa-envelope"></i> retzenergies@gmail.com</p>
                        <p className="">Horaires d'ouverture :</p>
                        <p className="">Du lundi au vendredi, de 7h30 à 18h00</p>              
                </div>
            </div>
            <PartenairesBannerContact className="partenairesContact" />
            <QualifBanner />

        </div >
    );
};

export default Contact;