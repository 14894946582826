import React, { useState, useEffect } from 'react';
import logo from '../utils/logo.png';
import "../styles/ventilation.css";
import logoMiniWhite from "../utils/logo-miniature-blanc.png"



const Banner2Ventilation = () => {
    return (
        <div class="containerVentilation">
            <div class="carreNoirVentilation">
                <div class="phrase1Ventilation">Ventilation</div>
                <img src={logoMiniWhite} alt="logo" className="logoMiniBanner" />
            </div>
        </div>
    );
};

export default Banner2Ventilation;