import "../styles/bouton.css"
import { NavLink } from 'react-router-dom';


const Bouton = () => {
    return (
        <NavLink to="/Contact">
            <div className="bouton">Contactez-nous</div >
        </NavLink>
    );
};

export default Bouton;