import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom';

import "../styles/partenairesBannerContact.css";

import logoqualipac from '../utils/logo-Qualipac.png';
import logoqualisol from '../utils/logo-Qualisol.png';
import rgechauffage from '../utils/rge-chauffage.png';
import rgeventilation from '../utils/rge-ventilation.png';



export class QualifBanner extends PureComponent {
  render() {
    return (
      <div className="partenairesBannerContact">
        <Link  className="partenairesBannerLogos" to={"https://www.qualit-enr.org/qualifications/qualipac/"} target="_blank">
          <img src={logoqualipac} alt="logo qualipac" className="partenairesBannerLogo" />
        </Link>
        <Link  className="partenairesBannerLogos" to={"https://www.qualit-enr.org/qualifications/qualisol/"} target="_blank">
          <img src={logoqualisol} alt="logo qualisol" className="partenairesBannerLogo" />
        </Link>
        <Link  className="partenairesBannerLogos" to={"https://www.qualit-enr.org/qualifications/chauffage/"} target="_blank">
          <img src={rgechauffage} alt="logo rge chauffage" className="partenairesBannerLogo" />
        </Link>
        <Link  className="partenairesBannerLogos" to={"https://www.qualit-enr.org/qualifications/ventilation-plus/"} target="_blank">
          <img src={rgeventilation} alt="logo rge ventilation" className="partenairesBannerLogo" />
        </Link>
      </div>
    )
  }
}

export default QualifBanner;