const Banner2 = () => {
    return (

        <div class="container">
            <div class="carreNoir">
                <div class="phrase1">Votre confort dans le respect de l'environnement</div>
            </div>
        </div>

    );
};

export default Banner2;