import "../styles/bouton2.css"
import { NavLink } from 'react-router-dom';


const Bouton2 = () => {
    return (
        <NavLink to="/Contact">
            <div className="bouton2">Contactez-nous</div >
        </NavLink>
    );
};

export default Bouton2;