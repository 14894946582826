import "../styles/home.css";


const BannerScroll = () => {
    return (

        <div class="containerScroll">
            <div class="rectangleScroll">
            </div>
        </div>

    );
};

export default BannerScroll;