import "../styles/mentionslegales.css";

import PartenairesBannerContact from "../components/PartenairesBannerContact";

const MentionsLegales = () => {
    return (
        <div>
            <div className="mentionsLegales">
                <h2>Mentions légales</h2>
                <p>EURL Retz Energies</p>
                <p>ZA du Butai</p>
                <p>5,impasse des Champs Fleuris</p>
                <p>44320 Chaumes en Retz</p>
                <p>Appelez-nous au : 06 65 43 53 06</p>
                <p>E-mail : retzenergies@gmail.com</p>
                <p>N° siret : 908 140 346 00028</p>
                <p>Directeur de publication : Bastien LEGRUSLEY</p>
                <p>Site hébergé par OVH</p>
                <p>Adresse postale : OVH SAS 2, rue Kellermann, 59100 Roubaix</p>
                <p>Email : support@ovh.com</p>
                <p>Créations du site : Nicolas Genius ROLLAND </p>
            </div>
            <PartenairesBannerContact />
        </div >
    );
};

export default MentionsLegales;