import BannerVentilation from "../components/BannerVentilation";
import VotreComposant from "../components/VotreComposant";
import PartenairesBannerContact from "../components/PartenairesBannerContact";
import bastien1 from "../utils/ventilation1.jpg";
import bastien02 from "../utils/ventilation2.jpg"

import logoRouge from "../utils/logo-miniature-rouge.png";
import Banner2Ventilation from "../components/Banner2Ventilation";
import Bouton2 from "../components/Bouton2";
import QualifBanner from "../components/QualifBanner";



const Ventilation = () => {
    return (
        <div>
            <BannerVentilation />
            <Banner2Ventilation />
            <Bouton2 />
            <VotreComposant
                logo={logoRouge}
                h1="Ventilation"
                image1={bastien1}
                image2={bastien02}
                alt="ma description"
                titre=""
                paragraphs={["Retz Energies est spécialisée dans le domaine de la ventilation.", "Nous pouvons vous apporter notre expertise afin d'améliorer votre ventilation existante ou pour l'installation de ventilation dans le respect des nouvelles normes", "Nous proposons des solutions pour:", "- Le remplacement de votre VMC.", "- L'installation de VMC simple ou double flux dans l'habitat.", "- L'installation de CTA (Centrale de Traitement de l'Air) pour les professionnels.", "", "Contactez-nous pour obtenir une étude et un devis gratuit."]}
            />
            <PartenairesBannerContact />
            <QualifBanner />
        </div>
    );
};

export default Ventilation;