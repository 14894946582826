import React, { useState, useEffect } from 'react';
import "../styles/bannerReno.css";
import logoMiniWhite from "../utils/logo-miniature-blanc.png"

const Banner2Reno = () => {
    return (
        <div class="containerReno">
            <div class="carreNoirReno">
                <div class="phrase1Reno">Rénovation énergétique</div>
                <img src={logoMiniWhite} alt="logo" className="logoMiniBanner" />
            </div>
        </div>
    );
};

export default Banner2Reno;