import React from 'react';
import ampouleVerte from "../utils/ampouleVerte.jpg";
import pacSurBriques from "../utils/pacSurBriques.jpg";
import panneauSolaire from "../utils/panneauSolaire.jpg";
import radiateurPre from "../utils/radiateurPre.jpg";



import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Carousel from "react-responsive-carousel/lib/js/components/Carousel/index";
import "../styles/slider.css";

function Slider() {
    return <Carousel className="carouselStyle"
    autoPlay={true} interval={4500} infiniteLoop showIndicators={false} animationHandler="fade" showStatus={false} showThumbs={false} transitionTime={800} >
        <img className='objectFit' src={ampouleVerte} alt="" />
        <img className='objectFit' src={pacSurBriques} alt="" />
        <img className='objectFit' src={panneauSolaire} alt="" />
        <img className='objectFit' src={radiateurPre} alt="" />
    </Carousel>
}

export default Slider;