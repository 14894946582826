import React from 'react';
import "../styles/bannerElec.css";
import "../styles/bannerReno.css";

import logoMiniWhite from "../utils/logo-miniature-blanc.png"



const Banner2Elec = () => {
    return (
        <div class="containerElec">
            <div class="carreNoirElec">
                <div class="phrase1Elec">Electricité Générale</div>
                <img src={logoMiniWhite} alt="logo" className="logoMiniBanner" />
            </div>
        </div>
    );
};

export default Banner2Elec;