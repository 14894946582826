import "../styles/footer.css";
import logoWhite from "../utils/logo-miniature-blanc.png";

const Footer = () => {
    return (
        <footer className=" footerContenu">
            <div className="footerStyle">
                <div className="pGaucheFooter">
                    <p className="">Zones d'interventions : Pays de Retz, Pornic, La plaine sur mer,</p>
                    <p className="">Saint Michel Chef Chef, Tharon, Saint Brévin les Pins, Saint Viaud,</p>
                    <p className="">Saint Père en Retz, Chauvé, Vue, Rouans, Chaumes en Retz, Chéméré,</p>
                    <p className="">Arthon en Retz, Sainte Pazanne, La Bernerie en retz, Les Moutiers</p>
                    <p className="">en Retz, Villeneuve en Retz, Bourgneuf, Machecoul, Saint Cyr en Retz,</p>
                    <p className="">Fresany en Retz, Port Saint Père, Bouaye, Le Pellerin, La Montagne </p>
                </div>
                <div className="centreFooter">
                    <img className="logoFooterStyle" src={logoWhite} alt="logo kasa blanc" />
                    <p className="pFooterStyle">2023 Retz Energies. All rights reserved</p>
                </div>
                <div className="pDroiteFooter">
                    <p className="">Horaires d'ouverture :</p>
                    <p className="">Du lundi au vendredi, de 7h30 à 18h00</p>
                    <p className="">Contacts :</p>
                    <p className="">5 impasse des CHamps Fleuris, 44320 Chaumes en Retz</p>
                    <p className=""><i class="fa-solid fa-phone"></i>  06.65.43.53.06</p>
                    <p className=""><i class="fa-solid fa-envelope"></i> retzenergies@gmail.com</p>
                    <a href="/MentionsLegales" className="mentionslegales">Mentions légales</a>
                </div>
            </div>
            <div className="centreFooterTest">
                <img className="logoFooterStyle" src={logoWhite} alt="logo kasa blanc" />
                <p className="pFooterStyle">2023 Retz Energies. All rights reserved</p>
            </div>
        </footer>
    );
};

export default Footer;
