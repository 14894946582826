import './App.css';
import Header from "./components/Header";
import Routes from "./routes";
import Footer from "./components/Footer";
import NavBar from "./components/NavBar";

import "./App.css";


import { BrowserRouter } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <NavBar />
        <Routes />
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;

