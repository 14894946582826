import React from 'react'
import "../styles/navbar.css";
import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../utils/logo.png';


function NavBar() {

    const [showLinks, setShowLinks] = useState(false)

    const handleShowLinks = () => {
        setShowLinks(!showLinks)
    }

    return (
        <nav className={`navbar ${showLinks ? "show-nav" : "hide-nav"} `}>
            <div className="logo-container">
                <NavLink to="/">
                    <img src={logo} alt="logo retzenergies" className="logoHeader" />
                </NavLink>
            </div>
            <ul className="navbar__links">
                <li className="navbar__item slideInDown-1">
                    <a href="/" className="navbar__link">Accueil</a>
                </li>
                <li className="navbar__item slideInDown-2">
                    <a href="/RenovationEnergetique" className="navbar__link">Rénovation énergétique</a>
                </li>
                <li className="navbar__item slideInDown-3">
                    <a href="/ElectriciteGenerale" className="navbar__link">Electricité Générale</a>
                </li>
                <li className="navbar__item slideInDown-4">
                    <a href="/Plomberie" className="navbar__link">Plomberie</a>
                </li>
                <li className="navbar__item slideInDown-5">
                    <a href="/Ventilation" className="navbar__link">Ventilation</a>
                </li>
                <li className="navbar__item slideInDown-6">
                    <a href="/Contact" className="navbar__link">Contact</a>
                </li>
            </ul>
            <button className="navbar__burger" onClick={handleShowLinks}>
                <span className="burger__bar"></span>
            </button>
        </nav>
    )
}

export default NavBar