import React, { useRef, useEffect, useState } from 'react';
import '../styles/votreComposant.css'; 

const VotreComposant = ({ image1, image2, alt, titre, paragraphs }) => {
  const [isVisible, setIsVisible] = useState(false);
  const conteneurRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
            observer.unobserve(entry.target);
          }
        });
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.1, 
      }
    );

    if (conteneurRef.current) {
      observer.observe(conteneurRef.current);
    }

    return () => {
      if (conteneurRef.current) {
        observer.unobserve(conteneurRef.current);
      }
    };
  }, []);

  return (
    <div className='blocGlobal'>
      <div
        className={`conteneurBloc ${isVisible ? 'visible' : ''}`}
        ref={conteneurRef}
      >
        <div className="partie-gauche">
          <img src={image1} alt={alt} className={`image ${isVisible ? 'fadeIn' : ''}`} />
          <img src={image2} alt={alt} className={`image ${isVisible ? 'fadeIn' : ''}`} />

        </div>
        <div className={`partie-droite ${isVisible ? 'slideIn' : ''}`}>
          <h2>{titre}</h2>
          {paragraphs.map((paragraph, index) => (
            <p key={index}>{paragraph}</p>
          ))}
        </div>
      </div>
    </div>
  );
};

export default VotreComposant;

