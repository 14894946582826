import "../styles/home.css";

import Banner2 from "../components/Banner2";
import Slider from "../components/Slider";

import logoMiniOrange from "../utils/logo-miniature-orange.png";
import logoMiniGreen from "../utils/logo-miniature-vert.png";
import logoMiniRed from "../utils/logo-miniature-rouge.png";
import logoMiniJade from "../utils/logo-miniature-jade.png";
import PartenairesBannerContact from "../components/PartenairesBannerContact";
import BannerScroll from "../components/BannerScroll";
import Bouton from "../components/Bouton";
import QualifBanner from "../components/QualifBanner";

const Home = () => {
    return (
        <div>
            <Slider />
            <Banner2 />
            <Bouton />
            <BannerScroll />
            <div className="textUnderBanner">
                <p className="h1TextUnder">Retz Energies</p>
                <p className="h2TextUnder">Entreprise basée au coeur du Pays de Retz, spécialisée dans la rénovation énergétique et dans les travaux de plomberie, électricité, chauffage, ventilation et climatisation pour les paticuliers et les professionnels.</p>
            </div>

            <div className="fourBox">
                <a href="/RenovationEnergetique" class="box">
                    <li>
                        <img src={logoMiniGreen} alt="logo" className="logoBox" />
                        <h1 className="h1Box">Rénovation Energétique</h1>
                        <p className="pBox">Retz Energies, entreprise RGE, propose des solutions de rénovation énergétique adaptées à votre projet.</p>
                    </li>
                </a>
                <a href="/ElectriciteGenerale" className="box">
                    <li >
                        <img src={logoMiniRed} alt="logo" className="logoBox" />
                        <h1 className="h1Box">Electricité Générale</h1>
                        <p className="pBox">La société propose des solutions en électicité dans le neuf et la rénovation dans l'habitat et le tertiaire.</p>
                    </li>
                </a>
                <a href="/Plomberie" className="box" >
                    <li >
                        <img src={logoMiniJade} alt="logo" className="logoBox" />
                        <h1 className="h1Box">Plomberie</h1>
                        <p className="pBox">Retz Energies vous accompagne dans vos travaux de plomberie sanitaire dans le neuf ou la rénovation.</p>
                    </li>
                </a>
                <a href="/Ventilation" className="box" >
                    <li>
                        <img src={logoMiniOrange} alt="logo" className="logoBox" />
                        <h1 className="h1Box">Ventilation</h1>
                        <p className="pBox">La société est spécialiste en ventilation pour l'habitat ou le tertiaire en accord avec la règlementation.</p>
                    </li>
                </a>
            </div>
            <PartenairesBannerContact />
            <QualifBanner />
        </div >
    );
};

export default Home;